import React from "react"
import styled from "styled-components"

const Label = styled.h5`
  margin: 0;
`
const Input = styled.input`
  width: 350px;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 0.3rem;
`

const TextArea = styled.textarea`
  width: 350px;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 0.3rem;
`
const SubmitBtn = styled.button`
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #4267b2;
  color: white;
  margin: 1rem 0;
`

const CheckOutAddressForm = props => {
  return (
    <form onSubmit={props.handleSubmittedForm} style={{ marginBottom: "3rem" }}>
      <h1 style={{ marginTop: "0" }}>Shipping Address</h1>
      <Label>Full Name (First and Last Name)</Label>
      <Input onChange={props.handleChange} name="name" required />
      <Label>Address line 1</Label>
      <Input
        onChange={props.handleChange}
        name="address1"
        placeholder="Street address, P.O. box, company name, c/o"
        required
      />
      <Label>Address line 2</Label>
      <Input
        onChange={props.handleChange}
        name="address2"
        placeholder="Apartment, suite, unit, building, floor, etc."
      />
      <Label>City</Label>
      <Input onChange={props.handleChange} name="city" required />
      <Label>State</Label>
      <Input onChange={props.handleChange} name="state" required />
      <Label>Zip Code</Label>
      <Input onChange={props.handleChange} name="zip" required />
      <Label>Phone number</Label>
      <Input
        onChange={props.handleChange}
        name="phone"
        required
        placeholder="(555) 555-5555"
      />
      <Label>Email</Label>
      <Input onChange={props.handleChange} type="email" name="email" required />
      <h4 style={{ margin: "0" }}>Delivery instructions (optional)</h4>
      <Label>Do we need additional instructions to find this address?</Label>
      <TextArea
        onChange={props.handleChange}
        name="delivery-instr"
        placeholder="Provide details such as building description, a nearby landmark, or other navigation instructions."
      />
      <br />
      <SubmitBtn>Submit Address</SubmitBtn>
    </form>
  )
}

export default CheckOutAddressForm
