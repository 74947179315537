import React from "react"
import styled from "styled-components"

const AddedToCartNotification = styled.p`
  margin-left: 20px;
  color: green;
  font-weight: bold;
`
const AlreadyInCartNotification = styled.p`
  margin-left: 20px;
  color: red;
  font-weight: bold;
`

const PartsDataLayout = styled.div`
  /* border: 1px solid black; */
  min-height: 300px;
  padding: 1.5rem;
  border-radius: 7px;
  margin: 1rem;
  display: flex;
  justify-content: space-evenly;
`

const ImageContainer = styled.div`
  width: 400px;
  height: 400px;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

const ProductData = styled.div`
  .product-description-header {
    border-bottom: 1px solid lightgrey;
    h2,
    p {
      margin: 0;
    }
    p {
      font-size: 1.2em;
    }
  }
  .price {
    font-size: 1.1em;
    p {
      margin: 0.5rem 0;
    }
  }

  select {
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.3rem;
  }
`

const CartBtn = styled.button`
  /* width: 80%; */
  background-color: yellow;
  background-image: linear-gradient(yellow, orange);
  border: 1px solid black;
  padding: 0.3rem;
  border-radius: 5px;
  margin: 1rem 0;
  cursor: pointer;
`

const PartsData = props => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  return (
    <PartsDataLayout>
      <ImageContainer>
        <img src={props.image} alt={props.description} />
      </ImageContainer>
      <ProductData>
        <div className="product-description-header">
          <h2>FactoryCat Part Number: {props.partNumber}</h2>
          <p>{props.description}</p>
        </div>
        <div className="price">
          <p>
            Price:{" "}
            <span style={{ color: "red" }}>
              {formatter.format(props.price)}
            </span>
            <p style={{ fontSize: ".7em" }}>
              Pricing shown does not include freight, duties, taxes, or other
              applicable fees.
            </p>
          </p>
        </div>
        <h3>
          Availability:{" "}
          <span style={{ fontWeight: "normal" }}>{props.availability}</span>{" "}
        </h3>
        <h3>
          Weight: <span style={{ fontWeight: "normal" }}>{props.weight}</span>{" "}
        </h3>
        <select value={props.qty} onChange={props.handleQty}>
          <option value="1">Qty 1</option>
          <option value="2">Qty 2</option>
          <option value="3">Qty 3</option>
          <option value="4">Qty 4</option>
          <option value="5">Qty 5</option>
          <option value="6">Qty 6</option>
          <option value="7">Qty 7</option>
          <option value="8">Qty 8</option>
          <option value="9">Qty 9</option>
          <option value="10">Qty 10</option>
        </select>
        <br />
        <div style={{ display: "flex" }}>
          <CartBtn onClick={props.handleCartArray}>
            <i className="fas fa-shopping-cart" /> Add to Cart
          </CartBtn>
          <AddedToCartNotification style={{ display: props.itemInCart }}>
            Added To Cart
          </AddedToCartNotification>
          <AlreadyInCartNotification
            style={{ display: props.itemAlreadyInCart }}
          >
            Item already exists in cart.
          </AlreadyInCartNotification>
        </div>
        <p style={{ maxWidth: "350px" }}>
          All parts listed on our website are FactoryCat OEM Parts for our
          Scrubbers, Sweepers, Burnishers, and EDGE equipment.
        </p>
      </ProductData>
    </PartsDataLayout>
  )
}

export default PartsData
