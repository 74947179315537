import React from "react"
import styled from "styled-components"

const CartBtn = styled.button`
  /* width: 80%; */
  background-color: yellow;
  background-image: linear-gradient(yellow, orange);
  border: 1px solid black;
  padding: 0.3rem;
  border-radius: 5px;
  margin: 1rem 0;
  cursor: pointer;
`

const CartLayout = styled.div`
  border: 1px solid black;
  min-height: 300px;
  padding: 1.5rem;
  border-radius: 7px;
  margin: 1rem;
`

const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  align-items: center;

  .cart-image {
    position: relative;
  }

  .cart-count {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 0.8em;
    text-align: center;
    line-height: 12px;
    padding: 0.2rem;
  }
`

const ProductContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  border: 1px solid black;
  border-radius: 6px;
  margin: 0.5rem;
  padding: 0.2rem;

  .fa-window-close {
    position: absolute;
    top: 3px;
    right: 3px;
    color: red;
  }

  .product-desc {
    p {
      margin: 0;
    }
  }

  .image-wrapper {
    max-width: 100px;
    max-height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  select {
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.2rem;
  }
`
const EditContainer = styled.div`
  border: 1px solid white;
  background-color: whitesmoke;
  border-radius: 7px;
  box-shadow: 10, 5, 10, 10;
`
const EditQtyInput = styled.input``

const Cart = props => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  return (
    <CartLayout>
      <CartHeader>
        <h2 style={{ margin: "0" }}>Shopping Cart</h2>
        <div className="cart-image">
          <i style={{ fontSize: "1.2em" }} className="fas fa-shopping-cart" />
          <div style={{ display: props.displayCartQty }} className="cart-count">
            {props.totalCartQty}
          </div>
        </div>
      </CartHeader>
      {props.cartArray.length < 1 && (
        <h3 style={{ color: "red" }}>Shopping cart is currently empty</h3>
      )}

      {props.cartArray &&
        props.cartArray.map(cart => {
          return (
            <ProductContainer key={cart.partNumber}>
              <i
                onClick={() => props.handleRemoveItem(cart.partNumber)}
                className="far fa-window-close"
              />
              <div className="product-desc">
                <p>
                  Part No:{" "}
                  <span style={{ fontWeight: "bold" }}>{cart.partNumber}</span>
                </p>
                <p style={{ fontSize: ".9em" }}>{cart.description}</p>
                <p>
                  <select
                    id={cart.partNumber}
                    value={cart.qty}
                    onChange={props.handleUpdatedQty}
                  >
                    <option value="1">Qty 1</option>
                    <option value="2">Qty 2</option>
                    <option value="3">Qty 3</option>
                    <option value="4">Qty 4</option>
                    <option value="5">Qty 5</option>
                    <option value="6">Qty 6</option>
                    <option value="7">Qty 7</option>
                    <option value="8">Qty 8</option>
                    <option value="9">Qty 9</option>
                    <option value="10">Qty 10</option>
                  </select>
                </p>
                Item Price: {formatter.format(cart.updatedPrice)}
              </div>
              <div className="image-wrapper">
                <img src={cart.image} alt={cart.description} />
              </div>
            </ProductContainer>
          )
        })}
      <div style={{ display: props.displayEditForm }} className="edit-qty">
        <EditQtyInput placeholder="Enter new Qty" />
        <button>Edit Qty</button>
      </div>
      {props.cartArray.length > 0 && (
        <>
          <h4 style={{ fontWeight: "normal" }}>
            Total Cart Price:{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatter.format(props.totalCartPrice)}
            </span>
          </h4>
          <CartBtn onClick={props.handleDisplayCheckOut}>
            <i className="fas fa-shopping-cart" /> Check Out
          </CartBtn>
        </>
      )}
    </CartLayout>
  )
}

export default Cart
