import React from "react"
import styled from "styled-components"

const ComponentLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid green;
`

const ItemImage = styled.img`
  max-width: 500px;
`
const ItemDescription = styled.div``

const ShopDescription = () => {
  return (
    <ComponentLayout>
      <ItemImage src="#" alt="bring in dynamically" />
      <ItemDescription>
        <h1>Here is an Item Description I think</h1>
      </ItemDescription>
    </ComponentLayout>
  )
}

export default ShopDescription
