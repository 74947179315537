import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import OemLogo from "../images/shop-images/oem-stamp.png"
import PartsShopLayout from "../components/partsShop/PartsShopLayout"
import PartsData from "../components/shop/PartsData"
import Cart from "../components/shop/Cart"
import TireImage from "../images/shop-wheel.jpg"
import HoseImage from "../images/hose-image.jpg"
import RedBladeImage from "../images/red-blade-image.jpg"
import BrushImage from "../images/grey-brush-image.jpg"

import { CartProvider, useShoppingCart } from "use-shopping-cart"
import { loadStripe } from "@stripe/stripe-js"

import CheckOut from "../components/shop/CheckOut"
import OrderSummary from "../components/shop/OrderSummary"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 2rem auto;
`

const ShopHeader = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 120px;
  }

  form {
    margin: 0 2rem;
  }
`
const Input = styled.input`
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
  width: 250px;
  margin-right: 15px;
`
const SearchBtn = styled.button`
  padding: 0.5rem;
  border-radius: 5px;
  background-color: orange;
  border: none;
  cursor: pointer;
`

const SearchSerialBtn = styled.button`
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #4267b2;
  color: white;
  border: none;
  margin: 0 0.5rem;
  cursor: pointer;
`

const ComponentLayout = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  /* grid-gap: 20px; */
`

const ErrorForm = styled.div`
  border: 1px solid black;
  min-height: 300px;
  padding: 1.5rem;
  border-radius: 7px;
  margin: 1rem;
`

const CheckOutLayout = styled.div`
  max-width: 1400px;
  margin: 3rem auto;
  display: flex;
  justify-content: space-around;
`

//END STYLES

const productData = [
  {
    name: "Back up Wheel",
    id: "21-7570",
    price: 790,
    image: TireImage,
    currency: "USD",
    product_data: {
      metadata: {
        weight: "0.11 lbs",
        availability: "In Stock",
      },
    },
  },
  {
    name: 'Hose, 1.5"ID 38.50"L, Squeegee Vac Hose',
    id: "253-0111",
    price: 2550,
    image: HoseImage,
    currency: "USD",
    product_data: {
      metadata: {
        weight: "0.92 lbs",
        availability: "In Stock",
      },
    },
  },
  {
    name: "Squeegee Blade,Front,Linatex",
    id: "25-755L",
    price: 3310,
    image: RedBladeImage,
    currency: "USD",
    product_data: {
      metadata: {
        weight: "0.60 lbs",
        availability: "In Stock",
      },
    },
  },
  {
    name: 'Brush,Cylindrical,28",Light',
    id: "285-821PS",
    price: 23490,
    image: BrushImage,
    currency: "USD",
    product_data: {
      metadata: {
        weight: "6.90 lbs",
        availability: "In Stock",
      },
    },
  },
]

//Test coded products
const product1 = {
  name: "Back up Wheel",
  id: "21-7570",
  currency: "USD",
  image: TireImage,
  partNumber: "21-7570",
  description: "Back up Wheel",
  price: 7.9,
  updatedPrice: "",
  availability: "In Stock",
  weight: "0.11 lbs",
  qty: 1,
}
const product2 = {
  name: 'Hose, 1.5"ID 38.50"L, Squeegee Vac Hose',
  id: "253-0111",
  currency: "USD",
  image: HoseImage,
  partNumber: "253-0111",
  description: 'Hose, 1.5"ID 38.50"L, Squeegee Vac Hose',
  updatedPrice: "",
  price: 25.5,
  availability: "In Stock",
  weight: "0.92 lbs",
  qty: 1,
}
const product3 = {
  name: "Squeegee Blade,Front,Linatex",
  id: "25-755L",
  currency: "USD",
  image: RedBladeImage,
  partNumber: "25-755L",
  description: "Squeegee Blade,Front,Linatex",
  updatedPrice: "",
  price: 33.1,
  availability: "In Stock",
  weight: "0.60 lbs",
  qty: 1,
}
const product4 = {
  name: 'Brush,Cylindrical,28",Light',
  id: "285-821PS",
  currency: "USD",
  image: BrushImage,
  partNumber: "285-821PS",
  description: 'Brush,Cylindrical,28",Light',
  updatedPrice: "",
  price: 234.9,
  availability: "In Stock",
  weight: "6.90 lbs",
  qty: 1,
}
// END test products

const stripePromise = loadStripe(
  "pk_test_51I6eFjDU2Q2BWbcEBnhsabSxBJfQWBsHHbDZpcXKyUMO0rtWVimNVgik8KqCpaIwBiuDW5SOMtYqWZRnMz6PLRYk00LAweTx90"
)

const PartsShopPage = () => {
  const [partNumber, setPartNumber] = useState("")
  const [visiblePartNumber, setVisiblePartNumber] = useState("")
  const [serialNumber, setSerialNumber] = useState("")
  const [updatedSerial, setUpdatedSerial] = useState("")
  const [serialData, setSerialData] = useState({})
  const [initialQty, setInitialQty] = useState("")
  const [cartQty, setCartQty] = useState("")
  const [displayCartQty, setDisplayCartQty] = useState("none")
  const [error, setError] = useState("none")
  const [success, setSuccess] = useState("none")
  const [cartDisplay, setCartDisplay] = useState("none")
  const [displayErrText, setDisplayErrText] = useState("")
  const [partsDisplay, setPartsDisplay] = useState("")
  const [cartArray, setCartArray] = useState([])
  const [product, setProduct] = useState({})
  const [displayEditForm, setDisplayEditForm] = useState("none")
  const [totalCartQty, setTotalCartQty] = useState("")
  const [totalCartPrice, setTotalCartPrice] = useState("")
  const [itemInCart, setItemInCart] = useState("none")
  const [itemAlreadyInCart, setItemAlreadyInCart] = useState("none")
  const [displayCheckOut, setDisplayCheckOut] = useState("none")
  const [displayMain, setDisplayMain] = useState("")

  //Variables for fetching serial number
  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const url = "https://www.tomcatequip.com/BornOnEP.php?action=bornonsm&serial="
  //Handle GET serial number
  async function handleData(sn) {
    const response = await fetch(`${CORS_PROXY}${url}${sn}`)
    response.json().then(response => setSerialData(response))
  }

  const handSerialClick = () => {
    setUpdatedSerial(serialNumber)
    setPartsDisplay("block")
  }
  const handleSerialChange = e => {
    setSerialNumber(e.target.value)
  }

  useEffect(() => {
    handleData(serialNumber)
  }, [serialNumber])

  const handleChange = e => {
    setPartNumber(e.target.value)
  }

  // const handleQty = e => {
  //   setProduct({ qty: e.target.value })
  // }

  const handleSubmit = e => {
    e.preventDefault()
    setItemAlreadyInCart("none")
    setItemInCart("none")
    setVisiblePartNumber(partNumber)
    setDisplayErrText("block")
    setCartDisplay("grid")
    if (partNumber === "21-7570") {
      setProduct(product1)
      setSuccess("block")
      setError("none")
    } else if (partNumber === "253-0111") {
      setProduct(product2)
      setSuccess("block")
      setError("none")
    } else if (partNumber === "25-755L") {
      setProduct(product3)
      setSuccess("block")
      setError("none")
    } else if (partNumber === "285-821PS") {
      setProduct(product4)
      setSuccess("block")
      setError("none")
    } else {
      setError("block")
      setSuccess("none")
      setDisplayErrText("block")
    }
    // setPartNumber("")
  }

  const handleSerialLookup = e => {
    e.preventDefault()
    setDisplayErrText("none")
    setError("block")
    setSuccess("none")
    setCartDisplay("grid")
  }

  // const handleQty = e => {
  //   setInitialQty(e.target.value)
  // }

  //sets Qty of the item before adding to cart
  const handleQty = e => {
    setProduct({
      ...product,
      qty: +e.target.value,
      updatedPrice: +e.target.value * +product.price,
      // updatedPrice: +e.target.value * +product.price,
    })
  }

  // handle cart counter

  // const handleCartArray = () => {
  //   let index = cartArray.findIndex(x => x.partNumber === partNumber)
  //   let newArray = cartArray.map(p => index === -1 ? {...p, qty: +p.qty + +product.qty, price: +p,qty * +product.price})
  // }

  const handleUpdatedQty = e => {
    const newArray = cartArray.map(p =>
      p.partNumber === e.target.id
        ? {
            ...p,
            qty: +e.target.value,
            updatedPrice: +e.target.value * +p.price,
          }
        : p
    )
    setCartArray(newArray)
  }

  // Add product to shopping cart array
  const handleCartArray = e => {
    //check to see if same part
    let index = cartArray.findIndex(x => x.partNumber === partNumber)
    if (index === -1) {
      product.updatedPrice = +product.price * +product.qty
      setCartArray([...cartArray, product])
      setDisplayCartQty("block")
      setItemInCart("block")

      //if part is the same only update qty
    } else {
      setItemInCart("none")
      setItemAlreadyInCart("block")
    }
  }

  //Remove item from shopping cart array
  const handleRemoveItem = partNumber => {
    const newProduct = cartArray.filter(item => item.partNumber !== partNumber)

    setCartArray(newProduct)
    setItemAlreadyInCart("none")
    setItemInCart("none")
    if (newProduct.length < 1) {
      setTotalCartQty(0)
    }
  }

  //Handle Qty Edit in Cart
  const EditCartQty = () => {
    setDisplayEditForm("block")
  }

  //handle total cart amount
  const HandleTotalCart = () => {
    if (cartArray.length > 0) {
      let newPrice = cartArray.reduce(function(prevPrice, currentPrice) {
        return {
          updatedPrice: +prevPrice.updatedPrice + +currentPrice.updatedPrice,
        }
      })
      setTotalCartPrice(newPrice.updatedPrice)
      // console.log(newPrice)
    }
  }

  const handleCartCounter = () => {
    if (cartArray.length > 0) {
      let newQty = cartArray.reduce(function(prevQty, currentQty) {
        return {
          qty: +prevQty.qty + +currentQty.qty,
        }
      })
      setTotalCartQty(newQty.qty)
      console.log(`array length ${cartArray}`)
      // if (cartArray === [{}]) {
      //   setTotalCartQty(0)
      // }
      // console.log(newPrice)
    }
  }

  const handleDisplayCheckOut = () => {
    setDisplayCheckOut("block")
    setDisplayMain("none")
  }

  const handleBackToCart = () => {
    setDisplayCheckOut("none")
    setDisplayMain("block")
  }

  // const handleAddPartFromLink = () => {
  //   if()
  // }

  useEffect(() => {
    handleCartCounter()
    HandleTotalCart()
  })

  return (
    <Layout>
      <PageLayout style={{ display: displayMain }}>
        <ShopHeader>
          <img src={OemLogo} alt="Genuine RPS Parts OEM" />
          <h1>FactoryCat - Factory Direct</h1>
          <form onSubmit={handleSubmit}>
            <Input
              id="serial-search"
              onChange={handleChange}
              type="text"
              value={partNumber}
              placeholder="Search Part Number"
            />

            <SearchBtn type="submit">
              <i className="fas fa-search" /> Search
            </SearchBtn>
            <SearchSerialBtn onClick={handleSerialLookup}>
              <i className="fas fa-search" /> Search Part By Serial Number
            </SearchSerialBtn>
          </form>
        </ShopHeader>

        <ComponentLayout style={{ display: cartDisplay }}>
          <div style={{ display: success }}>
            <PartsData
              partNumber={visiblePartNumber}
              image={product.image}
              description={product.description}
              price={product.price}
              availability={product.availability}
              weight={product.weight}
              handleQty={handleQty}
              qty={product.qty}
              handleCartArray={handleCartArray}
              initialQty={initialQty}
              itemInCart={itemInCart}
              itemAlreadyInCart={itemAlreadyInCart}
            />
          </div>
          <ErrorForm style={{ display: error }}>
            <h2 style={{ fontWeight: "normal", display: displayErrText }}>
              <span style={{ color: "red" }}>Sorry</span>, you entered a part
              number that does exist in our system.
            </h2>
            <p>
              If you need help finding the correct part number enter your serial
              number in the field below.
            </p>
            <Input
              value={serialNumber}
              onChange={handleSerialChange}
              placeholder="Please Enter Serial Number"
            />
            <SearchBtn onClick={handSerialClick}>
              <i className="fas fa-search" /> Search
            </SearchBtn>
            <div style={{ display: partsDisplay }}>
              {updatedSerial && (
                <div>
                  <h2>FactoryCat Serial Number: {serialData.serialNo}</h2>
                </div>
              )}
              {serialData.Brushes &&
                serialData.Brushes.map(brush => {
                  return (
                    <>
                      <ul>
                        <li>
                          <p>
                            {`${brush[0]}: `}
                            <span onClick={() => setProduct(product4)}>
                              {brush[1]}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </>
                  )
                })}
              {serialData.OtherParts &&
                serialData.OtherParts.map(otherPart => {
                  return (
                    <ul>
                      <li>
                        <p>{`${otherPart[0]}: ${otherPart[1]}`}</p>
                      </li>
                    </ul>
                  )
                })}
            </div>
          </ErrorForm>
          <Cart
            cartArray={cartArray}
            displayCartQty={displayCartQty}
            cartQty={cartQty}
            handleRemoveItem={handleRemoveItem}
            displayEditForm={displayEditForm}
            EditCartQty={EditCartQty}
            totalCartPrice={totalCartPrice}
            totalCartQty={totalCartQty}
            handleUpdatedQty={handleUpdatedQty}
            initialQty={initialQty}
            handleDisplayCheckOut={handleDisplayCheckOut}
          />
        </ComponentLayout>
      </PageLayout>
      <div style={{ display: displayCheckOut }}>
        <CheckOutLayout>
          <CheckOut
            handleRemoveItem={handleRemoveItem}
            cartArray={cartArray}
            handleUpdatedQty={handleUpdatedQty}
          />
          <OrderSummary
            cartArray={cartArray}
            handleBackToCart={handleBackToCart}
            totalCartQty={totalCartQty}
            totalCartPrice={totalCartPrice}
          />
        </CheckOutLayout>
      </div>
    </Layout>
  )
}

export default PartsShopPage
