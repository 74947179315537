import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  width: 600px;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  border: 1px solid grey;
  border-radius: 7px;
`
const ChangeBtn = styled.button`
  border: none;
  height: 30px;
  border-radius: 5px;
  background-color: #4267b2;
  color: white;
  padding: 0.3rem;
`

const ShippingView = props => {
  return (
    <StyledContainer>
      <h3>Shipping Address: </h3>
      <div>
        {props.formData.name}
        <br />
        {props.formData.address1}
        <br /> {props.formData.city}, {props.formData.state}{" "}
        {props.formData.zip}
      </div>
      <ChangeBtn onClick={props.handleChangeAddress}>Change Address</ChangeBtn>
    </StyledContainer>
  )
}

export default ShippingView
