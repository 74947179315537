import React from "react"
import styled from "styled-components"
import Cart from "./Cart"
import ShopDescription from "./ShopDescription"

const CartLayout = styled.div`
  display: flex;
  justify-content: space-around;
  border: 1px solid black;
  padding: 1rem;
`

const PartsShopLayout = () => {
  return (
    <CartLayout>
      <ShopDescription />
      <Cart />
    </CartLayout>
  )
}

export default PartsShopLayout
