import React, { useState } from "react"
import styled from "styled-components"
import CheckOutAddressForm from "./CheckOutAddressForm"
import ShippingView from "./ShippingView"

const StyledCartItem = styled.div`
  border-bottom: 1px solid lightgrey;
  display: flex;
  margin: 0 auto;
  .product-description {
    padding-left: 2rem;
    h4 {
      margin: 0;
    }
    select {
      border-radius: 5px;
      border: 1px solid black;
      padding: 0.2rem;
    }
  }

  .image-container {
    width: 150px;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const CheckOut = props => {
  const [formData, setFormData] = useState({})
  const [submittedForm, setSubmittedForm] = useState({})
  const [showCart, setShowCart] = useState("none")
  const [showAddress, setShowAddress] = useState("block")

  function handleChangeAddress() {
    setShowAddress("block")
    setShowCart("none")
  }

  function handleSubmittedForm(e) {
    e.preventDefault()
    setSubmittedForm({ formData })
    setShowCart("block")
    setShowAddress("none")
  }

  function handleChange(e) {
    const value = e.target.value
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  return (
    <>
      <div style={{ display: showAddress }}>
        <CheckOutAddressForm
          handleSubmittedForm={handleSubmittedForm}
          handleChange={handleChange}
        />
      </div>
      <div style={{ display: showCart }}>
        <h1 style={{ marginTop: "0" }}>Review Items and Shipping</h1>
        <ShippingView
          formData={formData}
          handleChangeAddress={handleChangeAddress}
        />
        <div>
          <h3>Items: </h3>
          {props.cartArray &&
            props.cartArray.map(cart => {
              return (
                <>
                  <StyledCartItem>
                    <div className="image-container">
                      <img src={cart.image} alt={cart.description} />
                    </div>
                    <div className="product-description">
                      <h4>{cart.description}</h4>
                      <h4 style={{ color: "red" }}>
                        {formatter.format(cart.price)}
                      </h4>
                      <select
                        id={cart.partNumber}
                        value={cart.qty}
                        onChange={props.handleUpdatedQty}
                      >
                        <option value="1">Qty 1</option>
                        <option value="2">Qty 2</option>
                        <option value="3">Qty 3</option>
                        <option value="4">Qty 4</option>
                        <option value="5">Qty 5</option>
                        <option value="6">Qty 6</option>
                        <option value="7">Qty 7</option>
                        <option value="8">Qty 8</option>
                        <option value="9">Qty 9</option>
                        <option value="10">Qty 10</option>
                      </select>
                      <p
                        onClick={() => props.handleRemoveItem(cart.partNumber)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        Remove Item
                      </p>
                    </div>
                  </StyledCartItem>
                </>
              )
            })}
        </div>
      </div>

      {/* <div>
          {props.cartArray &&
            props.cartArray.map(cart => {
              return (
                <>
                  <StyledCartItem>
                    <div className="image-container">
                      <img src={cart.image} alt={cart.description} />
                    </div>
                    <div className="product-description">
                      <h4>{cart.description}</h4>
                      <h4 style={{ color: "red" }}>
                        {formatter.format(cart.price)}
                      </h4>
                      <select
                        id={cart.partNumber}
                        value={cart.qty}
                        onChange={props.handleUpdatedQty}
                      >
                        <option value="1">Qty 1</option>
                        <option value="2">Qty 2</option>
                        <option value="3">Qty 3</option>
                        <option value="4">Qty 4</option>
                        <option value="5">Qty 5</option>
                        <option value="6">Qty 6</option>
                        <option value="7">Qty 7</option>
                        <option value="8">Qty 8</option>
                        <option value="9">Qty 9</option>
                        <option value="10">Qty 10</option>
                      </select>
                    </div>
                  </StyledCartItem>
                </>
              )
            })}
        </div> */}
    </>
  )
}

export default CheckOut
