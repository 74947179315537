import React from "react"
import styled from "styled-components"
import { loadStripe } from "@stripe/stripe-js"

const SummaryLayout = styled.div`
  border: 1px solid black;
  max-width: 300px;
  border-radius: 7px;
  max-height: 380px;
  padding: 1rem;
`
const SearchSerialBtn = styled.button`
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #4267b2;
  margin: 0.3rem 0;
  min-width: 300px;
  color: white;
  border: none;

  cursor: pointer;
`

const SummaryHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid lightgrey;

  p {
    font-size: 0.7em;
  }
`
const SummarySection = styled.div`
  border-bottom: 1px solid lightgrey;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
`
const PlaceOrderBtn = styled.button`
  background-color: yellow;
  min-width: 300px;

  background-image: linear-gradient(yellow, orange);
  border: 1px solid black;
  padding: 0.3rem;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
`

const OrderSummary = props => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  const stripePromise = loadStripe(
    "pk_test_51I6eFjDU2Q2BWbcEBnhsabSxBJfQWBsHHbDZpcXKyUMO0rtWVimNVgik8KqCpaIwBiuDW5SOMtYqWZRnMz6PLRYk00LAweTx90"
  )

  const handlePaymentClick = async e => {
    e.preventDefault()

    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "7.9",
          quantity: 2,
        },
        // {
        //   price: "3.21",
        //   quantity: 5,
        // },
      ],
      mode: "payment",
      successUrl: "https://www.factorycat.com/payment-success/",
      cancelUrl: "https://www.factorycat.com/payment-cancel/",
    })
  }

  return (
    <SummaryLayout>
      <SummaryHeader>
        <PlaceOrderBtn onClick={handlePaymentClick}>
          Place your order
        </PlaceOrderBtn>
        <SearchSerialBtn onClick={props.handleBackToCart}>
          <i className="fas fa-arrow-left" /> Continue Shopping
        </SearchSerialBtn>
        <p>
          We are only able to accept orders within the United States. For
          international call +001-262-681-6470{" "}
        </p>
      </SummaryHeader>
      <SummarySection>
        <h3>Order Summary</h3>
        <ul>
          <li>
            <p>items ({props.totalCartQty}):</p>{" "}
            <p>{formatter.format(props.totalCartPrice)}</p>
          </li>
          <li>
            <p>Shipping & handling:</p> <p>0</p>
          </li>
          <li>
            <p>Total before tax:</p> <p>$12.52</p>
          </li>
          <li>
            <p>Estimated tax to be collected:</p> <p>$112.52</p>
          </li>
        </ul>
      </SummarySection>

      <h3 style={{ color: "red" }}>
        Order Total <span>$136.49</span>
      </h3>
    </SummaryLayout>
  )
}

export default OrderSummary
